import Slider from 'react-slick';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router-dom';

const imageCardsgg = [
    {
        image: "https://firebasestorage.googleapis.com/v0/b/salonim-29dcf.appspot.com/o/images%2FnC39qij4zqUME908lUbJp9gsYdu1%2Fs-l1600%20(1).jpg?alt=media&token=95ae1791-2ded-4471-82fa-075299c7c01d",
        title: "People Taking Videos During Concert",
        date: "12-13 Mar 2024",
        location: "New York, USA",
        tag: "Event"
    },
    {
        image: "https://firebasestorage.googleapis.com/v0/b/salonim-29dcf.appspot.com/o/images%2FnC39qij4zqUME908lUbJp9gsYdu1%2Fs-l1600%20(1).jpg?alt=media&token=95ae1791-2ded-4471-82fa-075299c7c01d",
        title: "California Studio Open House",
        date: "15-16 Apr 2024",
        location: "California, USA",
        tag: "Education"
    },
    {
        image: "https://firebasestorage.googleapis.com/v0/b/salonim-29dcf.appspot.com/o/images%2FnC39qij4zqUME908lUbJp9gsYdu1%2Fs-l1600%20(1).jpg?alt=media&token=95ae1791-2ded-4471-82fa-075299c7c01d",
        title: "California Studio Open House",
        date: "15-16 Apr 2024",
        location: "California, USA",
        tag: "Education"
    },
    {
        image: "https://firebasestorage.googleapis.com/v0/b/salonim-29dcf.appspot.com/o/images%2FnC39qij4zqUME908lUbJp9gsYdu1%2Fs-l1600%20(1).jpg?alt=media&token=95ae1791-2ded-4471-82fa-075299c7c01d",
        title: "California Studio Open House",
        date: "15-16 Apr 2024",
        location: "California, USA",
        tag: "Education"
    },
    // Add more cards here...
];

interface ImageSliderProps {
    imageCards: any[];
}

const ImageSlider = ({ imageCards }: ImageSliderProps) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    return (
        <Box sx={{ padding: '40px 0px' }}>
            <Slider {...sliderSettings}>
                {imageCards.map((card, index) => (
                    <Box key={index} sx={{ padding: '20 10px' }}>
                        <Card
                            sx={{
                                borderRadius: '15px',
                                cursor: 'pointer'
                            }}
                            onClick={() => window.location.href = 'https://your-redirect-url.com'}
                        >
                            <CardMedia
                                component="img"
                                image={card.image}
                                alt="Placeholder"
                                sx={{ height: '200px', objectFit: 'cover' }}
                            />

                            <CardContent sx={{ padding: '16px', height: '100px' }}>
                                <Box
                                    sx={{
                                        backgroundColor: '#5D9BFC',
                                        borderRadius: '8px',
                                        padding: '4px 8px',
                                        display: 'inline-block',
                                        marginBottom: '8px',
                                        color: 'white',
                                        fontSize: '12px',
                                        fontWeight: '500'
                                    }}
                                >
                                    {card.tag}
                                </Box>
                                <Typography variant="h6" component="div" sx={{ fontWeight: '700' }}>
                                    {card.title}
                                </Typography>
                                <Box display="flex" alignItems="center" sx={{ color: '#AAAAAA', fontSize: '12px' }}>
                                    <Typography variant="h6" component="div" sx={{ fontWeight: '700' }}>
                                        Description
                                    </Typography>

                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Slider>
        </Box>

    );
};

export default ImageSlider;
