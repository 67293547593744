import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addUserToEvent, approveUserForEvent, getEventById } from '../services/eventServices';
import {
    Box,
    Typography,
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Grid
} from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useAuth } from '../context/AuthContext';

const MyEvent = () => {
    const { event_id } = useParams(); // Get event ID from the route
    const [event, setEvent] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { user } = useAuth();
    const [state, setState] = useState<string>('register');

    useEffect(() => {
        const fetchEvent = async () => {
            setLoading(true);
            try {
                const eventData = await getEventById(event_id); // Fetch the event by ID
                setEvent(eventData);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
            setLoading(false);
        };

        fetchEvent();
    }, [event_id]);

    if (loading) {
        return <Typography>Loading event details...</Typography>;
    }

    if (!event) {
        return <Typography>Event not found</Typography>;
    }

    const handleApproveModal = () => {
        setOpenModal(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    async function handleApproveGuest(id: any, status : string): Promise<void> {
        // Logic to approve the guest
        const response: any = await approveUserForEvent(event.event_id, id, status);

        if (response) {
            event.guests.map((guest: any) => {
                if (guest.user_id === id) {
                    guest.status = status;
                }
            });
        }
    }

    function handleRejectGuest(id: any): void {
        // Logic to reject the guest
    }

    return (
        <div>
            <Box>
                <Typography variant="h4">{event.event_name} Event</Typography>
                <Typography variant="subtitle1">{event.description || 'missing description'}</Typography>
            </Box>
            <LoadScript googleMapsApiKey="AIzaSyCMplCIFs55tQnyFOfBjZnp79r8vDgqbmY">
                <GoogleMap mapContainerStyle={containerStyle} center={{ lat: Number(event.location._lat), lng: Number(event.location._long) }} zoom={10}>
                    <Marker position={{ lat: Number(event.location._lat), lng: Number(event.location._long) }} />
                </GoogleMap>
            </LoadScript>

            <List>
                {event.guests.map((guest: any) => (
                    <ListItem key={guest.id} alignItems="flex-start">
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <ListItemAvatar>
                                            <Avatar src={guest.image} />
                                        </ListItemAvatar>
                                    </Grid>
                                    <Grid item>
                                        <ListItemText primary={guest.username} secondary={guest.email} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant="outlined" size="small" color={guest.status !== 'approve' ? 'success' : 'primary'} onClick={() => handleApproveGuest(guest.user_id, 'approved')}>
                                            Approve
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" size="small" color={guest.status !== 'pending' ? 'primary' : 'secondary'} onClick={() => handleApproveGuest(guest.user_id, 'pending')}>
                                            Pending
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" size="small" color={guest.status !== 'reject' ? 'primary' : 'error'} onClick={() => handleApproveGuest(guest.user_id, 'rejected')}>
                                            Reject
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

const containerStyle = {
    width: '100%',
    height: '200px',
};

export default MyEvent;
