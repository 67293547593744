import React, { useState } from 'react';
import { Box, Button } from '@mui/material';

// Define each tab's properties
const tabs = [
  { key: 'all', label: 'All Events', emoji: '', backgroundColor: '#5D9BFC', color: '#AAAAAA' },
  { key: 'past', label: 'Past Events', emoji: '', borderColor: '#EEEEEE', color: '#AAAAAA' },
  { key: 'my', label: 'My Events', emoji: '', borderColor: '#EEEEEE', color: '#AAAAAA' },
];

const EventsToggle = ({ onTabChange }: { onTabChange: (tab: string) => void }) => {
  const [activeTab, setActiveTab] = useState('all');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  return (
    <Box
      display="flex"
      gap="10px"
      position="relative"
      justifyContent="center"
      height="24px"
      alignItems="flex-start"
      flexDirection="row"
      padding="0px 0px 20px 0px"
    >
      {tabs.map((tab) => (
        <Button
          key={tab.key}
          onClick={() => handleTabChange(tab.key)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 15px',
            gap: '5px',
            width: 'auto',
            height: '24px',
            backgroundColor: activeTab === tab.key ? '#5D9BFC' : 'transparent',
            color: activeTab === tab.key ? '#FFFFFF' : tab.color,
            border: activeTab === tab.key ? 'none' : `1px solid ${tab.borderColor}`,
            borderRadius: '30px',
            fontFamily: 'Plus Jakarta Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '11px',
            lineHeight: '14px',
            textTransform: 'none',
          }}
        >
          <span style={{ fontSize: '11px' }}>{tab.emoji}</span>
          {tab.label}
        </Button>
      ))}
    </Box>
  );
};

export default EventsToggle;
