import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRouter from './Router';
import Header from './components/Header';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';  // Import AuthProvider
// import { handleForegroundMessages, requestPermissionAndGetToken } from './services/firebaseConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

const AppInitializer = () => {


  useEffect(() => {
    // Request permission and get FCM token
    // requestPermissionAndGetToken();

    // Handle messages while the app is in the foreground
    // handleForegroundMessages();
  }, []);

  if ('serviceWorker' in navigator) {
    console.log('Service Worker is supported', `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
        .then(function (registration) {
          console.log('Service Worker registration successful with scope: ', registration.scope);
        })
        .catch(function (err) {
          console.log('Service Worker registration failed: ', err);
        });
    }
  }

  return (
    <div style={{ height: '100%', backgroundColor: isMobile ? '#ffffff' : '#f9f7ed' }}>
      <Header />
      <AppRouter />
    </div>
  );
};

root.render(
  // <React.StrictMode>
  <AuthProvider> {/* Wrap your app with the AuthProvider */}
    <AppInitializer />
  </AuthProvider>
  // </React.StrictMode>
);

// serviceWorkerRegistration.register();
reportWebVitals();
