import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface MessageModalProps {
  open: boolean;
  onClose: () => void;
  onActionClick: () => void;
  header: string;
  message: string;
}

const MessageModal: React.FC<MessageModalProps> = ({ open, onClose, onActionClick, header, message }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styles.modal}>
        <CheckCircleOutlineIcon sx={styles.icon} fontSize="large" />
        <Typography id="modal-title" variant="h4" sx={styles.header}>
          {header}
        </Typography>
        <Typography id="modal-description" sx={styles.message}>
          {message}
        </Typography>
        <Button onClick={onActionClick} sx={styles.button} variant="contained">
        OK
        </Button>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    textAlign: 'center' as 'center',
  },
  icon: {
    color: '#4783e1',
    fontSize: '60px',
    mb: 2,
  },
  header: {
    fontWeight: 'bold',
    mb: 1,
  },
  message: {
    color: '#888',
    mb: 3,
    fontSize: '14px',
  },
  button: {
    mt: 2,
    backgroundColor: '#4783e1',
    color: 'white',
    borderRadius: '8px',
    width: '80%',
    padding: '10px 0',
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#346bb7',
    },
  },
};

export default MessageModal;
