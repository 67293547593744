// src/Router.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Events from './pages/Events';
import TabLayout from './components/TabLayout';
import EventDetail from './components/EventDetail';
import About from './pages/About';
import PhoneVerificationModal from './components/PhoneVerificationModal';
import MyEvent from './components/MyEvent';
import DrinksMenu from './components/DrinkMenu';
import UserProfile from './components/UserProfile';

const AppRouter: React.FC = () => (
  <Router>
    <TabLayout>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/verification" element={<PhoneVerificationModal />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:event_id" element={<EventDetail />} />
        <Route path="/events/managment/:event_id" element={<MyEvent />} />
        <Route path="/register" element={<Home />} />
        <Route path="/drinks" element={<DrinksMenu />} />
        <Route path="/live/:userId" element={<UserProfile />} />
        
        {/* Default route to redirect to /register */}
        <Route path="/" element={<Navigate to="/events" />} />
      </Routes>
    </TabLayout>
  </Router>
);

export default AppRouter;
