import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Event, Person, Info, Home, Height } from '@mui/icons-material'; // Import icons
import { Avatar } from '@mui/material';
import './overrides/TabOverrides.css';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

const TabLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div style={styles.containerStyle}>
            {/* Content Area */}
            <div style={styles.contentStyle}>{children}</div>

            {/* Footer Tabs */}
            <div style={styles.footerTabsStyle}>
                <div style={styles.backgroundStyle}>
                    <Tabs>
                        <TabList style={styles.tabListStyle}>
                            {/* Home Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/about" style={styles.linkStyle}>
                                    <Home style={styles.iconStyle} />
                                    <span style={{ color: '#5D9BFC' }}>About</span>
                                </Link>
                            </Tab>

                            {/* Favorite Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/favorite" style={styles.linkStyle}>
                                    <Person style={styles.iconStyle} />
                                    <span style={{ color: '#AAAAAA' }}>Favorite</span>
                                </Link>
                            </Tab>

                            {/* Elevated Discovery Tab */}
                            <Tab style={styles.elevatedTabStyle}>
                                <Link to="/" style={styles.linkStyle}>
                                    <Avatar style={{ height: '100%' , width : '100%'}}
                                        src={require('../assets/images/salonim.live.jpg')} // Replace with actual logo URL
                                        sx={styles.logo}
                                        alt="Salonim Logo"
                                    />
                                </Link>
                            </Tab>

                            {/* Ticket Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/events" style={styles.linkStyle}>
                                    <Event style={styles.iconStyle} />
                                    <span style={{ color: '#AAAAAA' }}>Events</span>
                                </Link>
                            </Tab>

                            {/* Profile Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/profile" style={styles.linkStyle}>
                                    <Person style={styles.iconStyle} />
                                    <span style={{ color: '#AAAAAA' }}>Profile</span>
                                </Link>
                            </Tab>
                        </TabList>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    containerStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : 'unset',
    },
    contentStyle: {
        flex: 1, // Takes up all remaining space above footer
        overflow: 'auto', // Allow content to scroll if needed
        paddingBottom: '40px', // Space for the footer tabs
        padding: '10px 8px',
    },
    footerTabsStyle: {
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        height: '70px',
        zIndex: 1000,
    },
    backgroundStyle: {
        width: '100%',
        height: '90px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px 30px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        gap: '10px',
    },
    tabListStyle: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '0px',
    },
    tabStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontFamily: 'Poppins, Arial, sans-serif',
        fontWeight: '500',
    },
    linkStyle: {
        color: '#4867B7',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textDecoration: 'none',
        gap: '5px',
    },
    iconStyle: {
        fontSize: '24px', // Adjust size of the icon
        color: '#AAAAAA',
    },
    elevatedTabStyle: {
        position: 'relative',
        width: '62px',
        height: '62px',
        zIndex: 1,
    },
    elevatedIconWrapper: {
        position: 'absolute',
        top: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    elevatedCircle: {
        width: '62px',
        height: '62px',
        backgroundColor: '#5D9BFC',
        borderRadius: '50%',
        boxShadow: '0px 4px 20px rgba(93, 155, 252, 0.25)',
        position: 'relative',
        zIndex: 0,
    },
    elevatedIcon: {
        fontSize: '24px',
        color: '#FFFFFF',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
    },
};

export default TabLayout;
