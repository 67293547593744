import React, { useState } from 'react';
import { Avatar, AvatarGroup, Button, Box, Typography, Link } from '@mui/material';

interface PeopleJoinedProps {
    guests: { image: string | undefined }[];
}

const PeopleJoined: React.FC<PeopleJoinedProps> = ({ guests }) => {
    const [expanded, setExpanded] = useState(false);

    // Toggle the expanded state
    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <Box>
            {/* Collapsed View */}
            {!expanded && (
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <Box display="flex" alignItems="center">
                        <AvatarGroup max={2}>
                            {guests.slice(0, 2).map((person: { image: string | undefined; }, index: React.Key | null | undefined) => (
                                <Avatar key={index} src={person.image} />
                            ))}
                        </AvatarGroup>
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '14px',
                                color: '#5D9BFC',
                                marginLeft: '8px',
                            }}
                        >
                            {`${guests.length.toLocaleString()} People are joined`}
                        </Typography>
                    </Box>
                    <Link
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '14px',
                            color: '#5D9BFC',
                            cursor: 'pointer',
                        }}
                        onClick={handleToggleExpand}
                    >
                        See All
                    </Link>
                </Box>
            )}

            {/* Expanded View */}
            {expanded && (
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))', // Auto-fit ensures that avatars fill available space horizontally
                            gap: '10px',
                            width: '100%', // Ensures the grid takes up the available width
                        }}
                    >
                        {guests.map((person: { image: string | undefined; }, index: React.Key | null | undefined) => (
                            <Avatar
                                key={index}
                                src={person.image}
                                sx={{ width: 80, height: 80 }} // Fixed avatar size
                            />
                        ))}
                    </Box>
                    <Button
                        sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '12px', color: '#5D9BFC', marginTop: '10px' }}
                        onClick={handleToggleExpand}
                    >
                        Show Less
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default PeopleJoined;
