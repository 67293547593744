import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Button, Paper, CircularProgress, Link } from '@mui/material';
import { styled } from '@mui/system';
import { fetchUserByDocumentId } from '../services/userService';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'top',
    minHeight: '80vh',
    backgroundColor: '#f3f4f6',
});

const StyledCard = styled(Paper)({
    maxWidth: '400px',
    width: '100%',
    minHeight: '80vh',
    padding: '30px',
    textAlign: 'center',
    borderRadius: '15px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
});

const StyledAvatar = styled(Avatar)({
    width: 100,
    height: 100,
    marginBottom: '50px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
});

const StyledButton = styled(Button)({
    backgroundColor: '#6C63FF',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '8px',
    padding: '10px 20px',
    marginTop: '20px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#5956e9',
    },
});

interface User {
    name: string;
    bio: string;
    profileImage: string;
    location: string;
    email: string;
}

const UserProfile = () => {
    const { userId } = useParams<{ userId: any }>();
    const [userData, setUserData] = useState<User | any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user = await fetchUserByDocumentId(userId);
                if (user) {
                    setUserData(user);
                } else {
                    console.log('User does not exist');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [userId]);

    if (loading) {
        return (
            <StyledContainer>
                <CircularProgress />
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            {userData ? (
                <StyledCard>
                    <StyledAvatar src={userData.profileImage} alt={userData.name} />
                    <Typography variant="h5" gutterBottom>
                        {userData.name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" style={{ marginBottom: '10px' }}>
                        {userData.bio}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Location: {userData.location}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
                        Email: {userData.email}
                    </Typography>
                    <StyledButton variant="contained">
                        Approve
                    </StyledButton>
                    <Link
                        href="#"
                        underline="none"
                        color="textSecondary"
                        style={{ display: 'block', marginTop: '15px' }}
                    >
                    </Link>
                </StyledCard>
            ) : (
                <Typography variant="h6">User data not found.</Typography>
            )}
        </StyledContainer>
    );
};

export default UserProfile;
