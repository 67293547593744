import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css';

type Drink = {
  name: string;
  price: number;
  image: string;
};

type DrinksData = {
  [key: string]: Drink[];
};

const drinksData: DrinksData = {
  "Hot Coffee": [
    { name: 'Espresso', price: 7.00, image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Tazzina_di_caff%C3%A8_a_Ventimiglia.jpg/1200px-Tazzina_di_caff%C3%A8_a_Ventimiglia.jpg' },
    { name: 'Cappuccino', price: 7.00, image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE55ZIrMBNiVuDG386CsA3QPIZUDKYD63b3Q&s' },
    { name: 'Latte', price: 7.50, image: 'https://upload.wikimedia.org/wikipedia/commons/d/d8/Caffe_Latte_at_Pulse_Cafe.jpg' },
    { name: 'Americano', price: 4.00, image: 'https://assets.beanbox.com/blog_images/AB7ud4YSE6nmOX0iGlgA.jpeg' }
  ],
  "Drinks": [
    { name: 'Caramel Frappuccino', price: 30.00, image: 'path/to/caramel_frappuccino.jpg' },
    { name: 'Mocha', price: 25.00, image: 'path/to/mocha.jpg' },
    { name: 'Chai Latte', price: 20.50, image: 'path/to/chai_latte.jpg' },
    { name: 'Iced Coffee', price: 18.00, image: 'path/to/iced_coffee.jpg' }
  ]
};

const DrinksMenu = () => {
  const [selectedTab, setSelectedTab] = useState('Hot Coffee');

  const handleTabChange = (event: any, newValue: React.SetStateAction<string>) => {
    setSelectedTab(newValue);
  };

  return (
    <Box p={2} sx={{ backgroundColor: '#d9ecff', borderRadius: '15px', textAlign: 'center' }}>
      <Box p={2} sx={{ backgroundColor: '#d9ecff', borderRadius: '15px', textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: '700', color: '#000', mb: 3 }}>
          ORDER DRINKS TO YOUR SEAT 🍹
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          sx={{ mb: 2 }}
        >
          {Object.keys(drinksData).map((category) => (
            <Tab key={category} value={category} label={category} sx={{ fontWeight: '600', fontSize: '15px' }} />
          ))}
        </Tabs>

        <Swiper
          spaceBetween={20}
          slidesPerView={'auto'}
          centeredSlides={true}
          pagination={{ clickable: true }}
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          {drinksData[selectedTab].map((drink: { image: string | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; price: number; }, index: React.Key | null | undefined) => (
            <SwiperSlide key={index} style={{ width: '65%' }}>
              <Box sx={{ textAlign: 'center' }}>
                <img src={drink.image} style={{ width: '60%', borderRadius: '30px', marginBottom: '10px' }} />
                <Typography variant="h6" sx={{ fontWeight: '600', mb: 1 }}>
                  {drink.name}
                </Typography>
                <Typography variant="body1" sx={{ color: '#5D9BFC', fontWeight: '500', mb: 2 }}>
                  {drink.price.toFixed(2)}₪
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#5D9BFC',
                    color: '#fff',
                    borderRadius: '30px',
                    padding: '10px 20px',
                    fontWeight: '600'
                  }}
                >
                  ORDER
                </Button>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box p={2} sx={{ backgroundColor: '#d9ecff', borderRadius: '15px', textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: '700', color: '#000', mb: 3 }}>
          ORDER SNACKS TO YOUR SEAT 🍹
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          sx={{ mb: 2 }}
        >
          {Object.keys(drinksData).map((category) => (
            <Tab key={category} value={category} label={category} sx={{ fontWeight: '600', fontSize: '15px' }} />
          ))}
        </Tabs>

        <Swiper
          spaceBetween={20}
          slidesPerView={'auto'}
          centeredSlides={true}
          pagination={{ clickable: true }}
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          {drinksData[selectedTab].map((drink: { image: string | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined; price: number; }, index: React.Key | null | undefined) => (
            <SwiperSlide key={index} style={{ width: '65%' }}>
              <Box sx={{ textAlign: 'center' }}>
                <img src={drink.image} style={{ width: '60%', borderRadius: '30px', marginBottom: '10px' }} />
                <Typography variant="h6" sx={{ fontWeight: '600', mb: 1 }}>
                  {drink.name}
                </Typography>
                <Typography variant="body1" sx={{ color: '#5D9BFC', fontWeight: '500', mb: 2 }}>
                  {drink.price.toFixed(2)}₪
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#5D9BFC',
                    color: '#fff',
                    borderRadius: '30px',
                    padding: '10px 20px',
                    fontWeight: '600'
                  }}
                >
                  ORDER
                </Button>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>

  );
};

export default DrinksMenu;
