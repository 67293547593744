import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app'; // Using compat if you are using FirebaseUI with compat
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { auth } from '../services/firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom'; // Use navigate for routing

const PhoneVerification  = () => {
    const navigate = useNavigate(); // React Router hook for navigation
    const location = useLocation(); // React Router hook for location
    const { data: event } = location.state || {}; // Destructure data from state 

    useEffect(() => {
        // Check if FirebaseUI instance already exists; otherwise, initialize it
        let ui = firebaseui.auth.AuthUI.getInstance();
        if (!ui) {
            ui = new firebaseui.auth.AuthUI(auth); // Use the initialized auth object here
        }

        // FirebaseUI configuration
        const uiConfig = {
            signInOptions: [
                {
                    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    recaptchaParameters: {
                        type: 'image', // 'audio' or 'image'
                        size: 'normal', // 'invisible' or 'compact' or 'normal'
                        badge: 'bottomleft', // 'bottomright' or 'inline' applies to invisible.
                    },
                    defaultCountry: 'IL', // Set default country
                    whitelistedCountries: ['+972'], // Specify whitelisted countries
                }
            ],
            callbacks: {
                signInSuccessWithAuthResult: (authResult: any) => {
                    console.log('User successfully signed in:', authResult);
                    const isNewUser = authResult.additionalUserInfo?.isNewUser;
                    // If it's a new user, perform some additional setup
                    if (isNewUser) {
                        console.log('This is a new user, setting up profile.');
                        // For new users, you can create a document in Firestore, etc.
                        navigate('/register', { state: { data: event} });
                    } else {
                        console.log('This is an existing user.');
                        navigate('/events');
                    }

                    // Close the modal on success

                    // Return false to prevent automatic redirection
                    return false;
                },
                signInFailure: (error: any) => {
                    console.error('Sign in failed:', error);
                    // Handle sign-in errors
                }
            },
            credentialHelper: firebaseui.auth.CredentialHelper.NONE, // Disable Google Auth credential helper
        };

        // Start the FirebaseUI widget
        ui.start('#firebaseui-auth-container', uiConfig);

        // Cleanup the FirebaseUI instance on component unmount
        return () => {
            if (ui) {
                ui.reset();
            }
        };
    }, [navigate]);

    return (
        <div>
            <h2>Phone Verification</h2>
            <div id="firebaseui-auth-container"></div> {/* FirebaseUI will render here */}
        </div>
    );
};

export default PhoneVerification;
