import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { addUserToEvent, getEventById } from '../services/eventServices';
import {
    Box,
    Typography,
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    IconButton,
    Grid,
    AvatarGroup,
} from '@mui/material';
import { LocationOn, ConfirmationNumber, Group, ArrowBack, CalendarToday, Favorite } from '@mui/icons-material';
import { Modal } from '@mui/material';
import PhoneVerificationModal from './PhoneVerificationModal';
import { useAuth } from '../context/AuthContext';
import ApproveDialogProps from './ApproveDialog';
import { addEventToUser, getLiverDataById, getUserByID, getUserEvents } from '../services/userService';
import CloseIcon from '@mui/icons-material/Close';
import { functions } from '../services/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import ImageSlider from './ImageSlider';
import { format } from 'date-fns';
import PeopleJoined from './PeopleJoined';
import WhatsAppButton from './WhatsappBtn';
import { checkIfUserPaidForEvent } from '../services/transactionService';
import Ticket from './Ticket';
import { useNavigate } from 'react-router-dom'; // Use navigate for routing
import MessageModal from './MessageModal';
import { register } from '../serviceWorkerRegistration';


const EventDetail = () => {
    const { event_id } = useParams(); // Get event ID from the route
    const [event, setEvent] = useState<any>(null);
    const [liver, setLiverData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [afterRegisterModal, setAfterRegisterModal] = useState<boolean>(false);
    const { user } = useAuth();
    const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
    const [openPaymentModal, setPaymentModal] = useState<boolean>(false);
    const [approvedPaymentModal, setApprovePaymentModal] = useState<boolean>(false);
    const [state, setState] = useState<string>('register');
    const [cardNumber, setCardNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState<any>(null);
    const [userPaid, setUserPaid] = useState<any>(null);
    const navigate = useNavigate(); // React Router hook for navigation
    const location = useLocation(); // React Router hook for location

    const handleOpenModal = () => {

        if (!user) {

            const serializableEvent = {
                id: event.id,
                name: event.name,
                date: event.date,
                // Add other necessary serializable properties here
            };

            navigate('/verification', { state: { data: serializableEvent } });
            setOpenModal(true);
        }
        else {
            if (state === 'register') {
                if (!user.user) {
                    navigate('/register', { state: { data: { id: event.id, name: event.name, date: event.date } } });
                }
                else {
                    addEventToUser(user.user.uid, event_id || '');
                    addUserToEvent(event_id || '', user);
                    setAfterRegisterModal(true);
                    setState('pending');
                }

            }
            else if (state === 'approved') {
                setPaymentModal(true);
            }
        }
    };

    useEffect(() => {

        if (event_id && user && user.user) {
            debugger;
            const checkEventInUser = async () => {
                try {
                    const userEvents = await getUserEvents(user.user.uid); // Fetch user's events
                    const eventExists = userEvents.filter((event: any) => event.event_id === event_id);


                    if (eventExists.length > 0) {
                        setState(eventExists[0].status);
                    }

                    if (eventExists[0].status === 'register') {
                        setAfterRegisterModal(true);
                    }

                    if (state === 'pending') {
                        handleOpenModal();
                    }

                    // handleOpenModal();


                } catch (error) {
                    console.error('Error checking event in user:', error);
                }
            };

            const isUserPaid = async () => {
                try {
                    const userPaid: any = await checkIfUserPaidForEvent(event_id, user.user.uid);
                    if (userPaid?.paid) {
                        setUserPaid(userPaid);
                        setState('paid');
                    }
                } catch (error) {
                    console.error('Error checking if user paid:', error);
                }
            };

            checkEventInUser();
            isUserPaid();

        }

        const fetchEvent = async () => {
            setLoading(true);
            try {
                const eventData = await getEventById(event_id); // Fetch the event by ID
                setEvent(eventData);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
            setLoading(false);
        };

        fetchEvent();
    }, [user]);

    useEffect(() => {

        const fetchLivers = async () => {
            if (event) {
                const liverArray = [];
                for (const speaker of event.livers) {
                    try {
                        const liverData: any = await getLiverDataById(speaker); // Assuming getUserEvents fetches user data
                        const liver = {
                            image: liverData.speaker_image,
                            title: liverData.inspiring_topic,
                            description: "12-13 Mar 2024",
                            location: "New York, USA",
                            tag: liverData.topics
                        };
                        liverArray.push(liver);
                    } catch (error) {
                        console.error(`Error fetching data for speaker ${speaker.userId}:`, error);
                    }
                }
                if (liverArray.length === 1) {
                    liverArray.push(liverArray[0]);
                }
                setLiverData(liverArray);
            }
        };

        fetchLivers();
    }, [event]);

    if (loading) {
        return <Typography>Loading event details...</Typography>;
    }

    if (!event) {
        return <Typography>Event not found</Typography>;
    }

    event.relatedItems = [
        {
            title: "ארז גרנוט",
            description: "סיפור על איך הוא יצא ממוסד פסיכיאטרי בצורה מוצלחת אחרי שנים של מאבק.",
            image: "path/to/image1.jpg", // Replace with actual image path
        },
        {
            title: "ניצן בראון",
            description: "איך כתבתי ספר ילדים אחרי שאימצתי כלב אחד בשם סימבה אחרי המלחמה.",
            image: "path/to/image2.jpg", // Replace with actual image path
        },
        {
            title: "ניצן בראון",
            description: "המשך לסיפור של סימבה וכיצד זה השפיע על חיי.",
            image: "path/to/image3.jpg", // Replace with actual image path
        },
        {
            title: "ליאור קורן",
            description: "איך הפכתי להיות מנטור בתחום השיווק לאחר שינויים מהותיים בחיי.",
            image: "path/to/image4.jpg", // Replace with actual image path
        },
    ]

    const handlePaymentSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setLoading(true);
        setMessage(''); // Reset message

        try {
            // Call mock payment function
            const mockPayment = httpsCallable(functions, 'mockPayment');

            const result: any = await mockPayment({
                user_id: user.user.uid,
                event_id: event_id,
                cardNumber: '1234-5678-9012-3456',
                amount: 100,
            });

            // Handle result

            // Display the result message
            setMessage(result.data);
            setPaymentModal(false);
            setApprovePaymentModal(true);
        } catch (error) {
            console.error('Error processing payment:', error);
            setMessage('An error occurred during payment processing.');
        } finally {
            setLoading(false);
        }
    };

    const handleDonePayment = () => {
        // setOpenApproveModal(true);
    };

    const handleDoneRequest = () => {
        setAfterRegisterModal(false);
    };

    const handleGetTicket = () => {
        setOpenApproveModal(true);
    };

    const handleApproveModal = () => {
        setOpenApproveModal(false);
        setApprovePaymentModal(false);
    };


    const handleCloseModal = () => {
        // setOpenModal(false);
    };

    return (
        <Box p={2} style={{ direction: 'ltr', position: 'relative', paddingBottom: '70px' }}>
            {/* Header Image and Menu Bar */}
            <Box position="relative" mb={2}>
                <img
                    src={event.event_image}
                    alt="Event"
                    style={{ minHeight: '200px', width: '100%', borderRadius: '30px', height: '350px', objectFit: 'cover' }}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    position="absolute"
                    top="35px"
                    left="35px"
                    right="35px"
                >
                    {/* <Button
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            backdropFilter: 'blur(2.5px)',
                            borderRadius: '50px',
                            padding: '15px',
                            minWidth: '44px',
                            height: '44px',
                            color: '#fff',
                        }}
                    >
                        <Favorite />
                    </Button> */}
                </Box>
            </Box>

            {/* Tags */}
            {/* <Box display="flex" justifyContent="space-between" mb={2}> */}
            {/* <Button
                    sx={{
                        backgroundColor: '#5D9BFC',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '8px 12px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '500',
                        fontSize: '11px',
                    }}
                >
                    Theatre
                </Button>
                <Button
                    sx={{
                        backgroundColor: '#5D9BFC',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '8px 12px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '500',
                        fontSize: '11px',
                    }}
                >
                    Invite
                </Button> */}
            {/* <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '9px',
                        color: '#AAAAAA',
                    }}
                >
                    {`Per Person `}
                    <Typography variant="h6" display="inline" fontWeight="bold" color="text.primary">
                        ${event.price}
                    </Typography>
                </Typography>
            </Box> */}

            {/* Event Title */}
            <Typography
                variant="h5"
                fontWeight="700"
                sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '22px',
                    marginBottom: '20px',
                }}
            >
                {event.event_name}
            </Typography>

            {/* Organizer and Interested Button */}
            {/* <Box display="flex" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center">
                    <Avatar src={event.organizerAvatar} sx={{ width: 20, height: 20, marginRight: '8px' }} />
                    <Typography variant="body2" sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '10px', color: '#AAAAAA' }}>
                        Organized by {event.event_name}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color={state === 'pending' ? 'secondary' : state === 'approved' || 'paid' ? 'success' : 'primary'}
                    size="large"
                    sx={{ borderRadius: '50px', padding: '10px 30px' }}
                    onClick={handleOpenModal}
                >
                    {state === 'pending' ? 'ממתין לאישור המארח' : state === 'approved' ? 'מאושר לחץ לתשלום' : state === 'paid' ? 'שולם' : 'אני רוצה להרשם'}
                </Button>
            </Box> */}

            {/* Divider */}
            <Box sx={{ borderBottom: '1px solid #EEEEEE', marginY: '16px' }} />

            {/* Date */}
            <Box display="flex" alignItems="center" mb={3}>
                <Box
                    sx={{
                        backgroundColor: 'rgba(93, 155, 252, 0.15)',
                        borderRadius: '30px',
                        padding: '9px 10px',
                        marginRight: '10px',
                    }}
                >
                    <CalendarToday sx={{ color: '#5D9BFC' }} />
                </Box>
                <Box>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '13px', color: '#000000' }}>
                        {event.event_date?.seconds ? (
                            format(new Date(event.event_date.seconds * 1000), 'MMMM dd, yyyy at hh:mm a')
                        ) : 'Invalid date'}
                    </Typography>
                </Box>
            </Box>

            {/* Location */}
            <Box display="flex" alignItems="center" mb={3}>
                <Box
                    sx={{
                        backgroundColor: 'rgba(93, 155, 252, 0.15)',
                        borderRadius: '30px',
                        padding: '9px 10px',
                        marginRight: '10px',
                    }}
                >
                    <LocationOn sx={{ color: '#5D9BFC' }} />
                </Box>
                <Box>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '13px', color: '#000000' }}>
                        {event.locationName}, IL
                    </Typography>
                    <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '11px', color: '#AAAAAA' }}>
                        {event.locationDetails}
                    </Typography>
                </Box>
            </Box>

            {/* Add to Calendar and Get Location Buttons */}
            <Box display="flex" gap={2} mb={3} justifyContent="center">
                {state === 'paid' && (
                    <Button
                        sx={{
                            backgroundColor: '#5D9BFC',
                            color: '#fff',
                            borderRadius: '50px',
                            padding: '11px 15px',
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '11px',
                            fontWeight: '600',
                        }}
                        onClick={handleGetTicket}
                    >
                        Show Ticket
                    </Button>
                )}
                <Button
                    variant="contained"
                    color={state === 'pending' ? 'secondary' : state === 'approved' || 'paid' ? 'success' : 'primary'}
                    size="large"
                    sx={{ borderRadius: '50px', padding: '10px 30px', fontSize: '11px' }}
                    onClick={handleOpenModal}
                >
                    {state === 'pending' ? 'Pending approval' : state === 'approved' ? 'APPROVED' : state === 'paid' ? 'PAID' : 'REGISTER'}
                </Button>
            </Box>

            {/* People Joined */}
            <PeopleJoined guests={event.guests} />
            {liver && liver.length > 0 && <ImageSlider imageCards={liver} />}
            <Box>
                <Typography variant="h6" sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '17px', fontWeight: '700', marginBottom: '10px' }}>
                    Description Event
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontSize: '13px', lineHeight: '20px', color: '#AAAAAA' }}>
                    {event.description}
                </Typography>
            </Box>
            {afterRegisterModal && (
                <MessageModal
                    open={afterRegisterModal}
                    onClose={handleDoneRequest}
                    //  onActionClick={goToHome}
                    header="We got your request!"
                    message="We will review your request and get back to you shortly"
                    onActionClick={handleDoneRequest} />
            )}

            {openApproveModal && (
                <Modal open={openApproveModal} onClose={handleApproveModal}>
                    <Box sx={{
                        width: 'calc(100% - 32px)',
                        maxWidth: 400,
                        maxHeight: '60%',
                        p: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        outline: 'none',
                    }}>
                        <Ticket event={event} user={user} booking={userPaid}></Ticket>
                    </Box>
                </Modal>
            )}

            {openPaymentModal && (
                <Modal open={openPaymentModal} onClose={handleDonePayment}>
                    <Box sx={{
                        // width: 'calc(100% - 32px)',
                        backgroundColor: 'white',
                        whiteSpace: 'nowrap',
                        maxHeight: '60%',
                        p: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        outline: 'none',
                    }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setPaymentModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <WhatsAppButton event={event}></WhatsAppButton>
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export default EventDetail;
