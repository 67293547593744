import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Avatar, Typography, IconButton, Divider, Modal } from '@mui/material';
import { ArrowBack, PhotoCamera } from '@mui/icons-material';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { updateUserDocument, uploadImageAndSaveToFirestore } from '../services/userService';
import { useLocation, useNavigate } from 'react-router-dom'; // Use navigate for routing

const RegisterScreen = () => {
    const { user, updateUser } = useAuth();
    const [image, setImage] = useState<File | null>(null);
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [bioError, setBioError] = useState(''); // State for bio error message
    const [gender, setGender] = useState('');
    const [network, setNetwork] = useState('');
    const [age, setUserAge] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
    const navigate = useNavigate(); // React Router hook for navigation
    const location = useLocation(); // React Router hook for location
    const { data: event } = location.state || {}; // Destructure data from state

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        // if (!username || !email || !phoneNumber || !age || !bio || !gender) {
        //     alert('Please fill in all required fields.');
        //     return;
        // }

        if (bio.length < 80) {
            setBioError('Bio must be at least 80 characters long.');
            return;
        } else {
            setBioError('');
        }

       
        const data = await updateUserDocument(user.uid || user.user.uid, {
            username,
            email,
            phoneNumber,
            age,
            bio,
            gender,
            network,
        });
        if (image) {
            await uploadImageAndSaveToFirestore(user.uid || user.user.uid, image);
        }
        updateUser(data);
        navigate('/events/' + event.id, { state: { isRegister: true } });
    };

    return (
        <Box sx={styles.container}>
            {/* Header */}
            <Typography variant="h4" sx={styles.title}>Create your account</Typography>
            <Typography variant="body2" sx={styles.subtitle}>
                Fill your profile to connect with the right events and people.
            </Typography>

            {/* Profile Picture Upload */}
            <Box sx={styles.header}>
                {image ? (
                    <Avatar src={URL.createObjectURL(image)} sx={styles.avatar} />
                ) : (
                    <Avatar sx={styles.avatar}>
                        <PhotoCamera />
                    </Avatar>
                )}
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={handleImageUpload}
                />
                <label htmlFor="icon-button-file">
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        sx={styles.uploadButton}
                    >
                        <PhotoCamera />
                    </IconButton>
                </label>
            </Box>

            {/* Registration Form */}
            <Box sx={styles.form}>
                <Typography sx={styles.label}>Full Name</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={styles.textField}
                    placeholder="Your Name"
                    required
                />

                <Typography sx={styles.label}>Email</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={styles.textField}
                    placeholder="example@gmail.com"
                    required
                />

                <Typography sx={styles.label}>Phone Number</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    disabled={user?.phoneNumber ? true : false}
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    sx={styles.textField}
                    placeholder="+1 234 567 890"
                    required
                />

                <Typography sx={styles.label}>Date of Birth</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="date"
                    value={age}
                    onChange={(e) => setUserAge(e.target.value)}
                    sx={styles.textField}
                    required
                />

                <Typography sx={styles.label}>Bio</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    sx={styles.textField}
                    placeholder="Tell us something about yourself, your work, hobbies, and passions."
                    error={!!bioError} // Show error if bioError is not empty
                    helperText={bioError} // Display the error message
                    required
                />

                <Typography sx={styles.label}>Gender</Typography>
                <RadioGroup
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    sx={styles.radioGroup}
                >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroup>

                <Button variant="contained" fullWidth sx={styles.submitButton} onClick={handleSubmit}>
                    Sign Up
                </Button>

                <Typography variant="body2" sx={styles.footerText}>
                    Already have an account? <span onClick={() => window.history.back()} style={styles.link}>Sign In</span>
                </Typography>
            </Box>
            {/* {bioError && (
                <Modal open={!!bioError} >
                    <Box
                        sx={{
                            width: 'calc(100% - 32px)',
                            maxWidth: 400,
                            maxHeight: '60%',
                            bgcolor: 'background.paper',
                            p: 2,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            outline: 'none',
                        }}
                    >
                    </Box>
                </Modal>
            )} */}
        </Box>
    );
};

const styles = {
    container: {
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        textAlign: 'center' as 'center',
    },
    header: {
        position: 'relative' as 'relative',
        marginBottom: '20px',
    },
    backButton: {
        position: 'absolute' as 'absolute',
        top: '10px',
        left: '10px',
    },
    title: {
        fontWeight: 'bold',
        marginBottom: '8px',
        fontSize: '28px',
        color: '#1a1a1a',
    },
    subtitle: {
        color: '#888',
        marginBottom: '24px',
        fontSize: '14px',
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '16px',
    },
    avatar: {
        width: '80px',
        height: '80px',
        margin: 'auto',
        backgroundColor: '#f0f0f0',
    },
    uploadButton: {
        position: 'absolute' as 'absolute',
        bottom: '0',
        right: '50%',
        transform: 'translateX(50%)',
        backgroundColor: '#f0f0f0',
    },
    form: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '15px',
        textAlign: 'left' as 'left',
    },
    label: {
        fontWeight: 'bold',
        color: '#333',
        fontSize: '14px',
        marginBottom: '4px',
    },
    textField: {
        borderRadius: '8px',
        backgroundColor: '#f5f5f5',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            fontSize: '14px',
        },
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    submitButton: {
        backgroundColor: '#4783e1',
        color: 'white',
        fontWeight: 'bold',
        padding: '12px 0',
        fontSize: '16px',
        marginTop: '16px',
        borderRadius: '10px',
    },
    divider: {
        margin: '20px 0',
        fontSize: '14px',
        color: '#aaa',
    },
    socialButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '10px',
    },
    socialButton: {
        borderRadius: '50%',
        width: '48px',
        height: '48px',
    },
    footerText: {
        textAlign: 'center' as 'center',
        marginTop: '20px',
        fontSize: '14px',
        color: '#555',
    },
    link: {
        color: '#4783e1',
        cursor: 'pointer',
    }
};

export default RegisterScreen;
