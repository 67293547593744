// src/firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDPAI5JpsJIOmNFIsnc4vhCzT09rhk4B0c",
  authDomain: "salonim.live",
  projectId: "salonim-29dcf",
  storageBucket: "salonim-29dcf.appspot.com",
  messagingSenderId: "694528172329",
  appId: "1:694528172329:web:1d0c2417de32664b16d738",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// const messaging = getMessaging(firebaseApp);
export const auth = firebaseApp.auth();
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);

// export const requestPermissionAndGetToken = async () => {
//   try {
//     console.log('Requesting permission...');
//     const permission = await Notification.requestPermission();

//     if (permission === 'granted') {
//       console.log('Notification permission granted.');
//       // Get the FCM token
//       const fcmToken = await getToken(messaging, { vapidKey: "BIjEe6-j-bOzsLVZjVcgjf6N37l1xq7L4-Rhf2gqAbFdBUf4boLVcbWD_V7Jt5tNocEO1KbItPhj24SFUnXSp5Q" });
//       console.log("FCM Token:", fcmToken);
//       return fcmToken;  // Send this token to your server for later use
//     } else {
//       console.log('Unable to get permission to notify.');
//     }
//   } catch (error) {
//     console.error('Error getting token', error);
//   }
// };

// // Handle foreground notifications
// export const handleForegroundMessages = () => {
//   onMessage(messaging, (payload) => {
//     console.log('Message received in foreground: ', payload);
//     // You can customize how the notification is displayed while the app is in the foreground
//   });
// };


// export const getFcmToken = async () => {
//   try {
//     const token = await getToken(messaging, { vapidKey: 'BIjEe6-j-bOzsLVZjVcgjf6N37l1xq7L4-Rhf2gqAbFdBUf4boLVcbWD_V7Jt5tNocEO1KbItPhj24SFUnXSp5Q' });
//     console.log('FCM Token:', token);
//     return token;
//   } catch (error) {
//     console.error('Error getting FCM token:', error);
//   }
// };

// Handle FCM token change (no explicit token refresh event in Firebase v9)
// export const refreshFcmToken = () => {
//   getToken(messaging, { vapidKey: 'YOUR_VAPID_KEY' })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log('Updated FCM Token:', currentToken);
//         // Send the updated token to your server if necessary
//       } else {
//         console.warn('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       console.error('Error retrieving updated FCM token: ', err);
//     });
// };

export default firebaseApp;
