import { useAuth } from '../context/AuthContext';
import { format } from 'date-fns';

interface WhatsAppButtonProps {
    event: any; // Replace 'any' with the appropriate type if known
}

const WhatsAppButton = ({ event }: WhatsAppButtonProps) => {
    console.log(event);
    const { user } = useAuth();
    const openWhatsApp = () => {
        const date = event.event_date?.seconds ? (
            format(new Date(event.event_date.seconds * 1000), 'MMMM dd, yyyy at hh:mm a')
        ) : 'Invalid date';
        const phoneNumber = '+972515885760'; // Recipient's phone number (in international format without + or 00)
        const message = encodeURIComponent(`Hello, I am intresting to pay for the event ${event.event_name} in ${event.locationName} at ${date}`); // Pre-ready message
        const url = `https://wa.me/${phoneNumber}?text=${message}`;

        window.open(url, '_blank'); // Open WhatsApp with the pre-filled message
    };

    return (
        <button onClick={openWhatsApp} style={{ margin: '19px', padding: '10px 20px', backgroundColor: '#25D366', color: '#fff', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // You can replace this with your local file or another link
                alt="WhatsApp Logo"
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
            />
            Proceed with WhatsApp
        </button>
    );
};

export default WhatsAppButton;
